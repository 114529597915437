import React from 'react';
import { Link } from 'gatsby';

import { Article } from '../types/Article';

function ArticleCardCmp({ title, author, description, image, slug }: Article) {
  return (
    <div className='flex flex-col justify-center p-5 mt-5 rounded-md shadow-lg lg:justify-start dark-text article-card grow'>
      <header className='flex flex-row items-center gap-3 pt-2 pl-2'>
        <img
          src={image}
          className='rounded-full'
          alt=''
          width={30}
          height={30}
        />
        <div className='italic'>{author}</div>
      </header>

      <div className='flex flex-col col-span-3 grow'>
        <h2 className='pt-3 text-lg font-bold'>{title}</h2>
        <p className='pt-2 text-sm font-light text-left'>{description}</p>
      </div>

      <footer className='flex flex-row items-center justify-center gap-3 pt-7'>
        <Link
          to={`/article/${slug}`}
          className='px-4 py-2 text-white rounded shadow-sm sm:relative sm:top-4 hover:no-underline bg-primary hover:bg-secondary'
        >
          <span className=''>Read more</span>
        </Link>
      </footer>
    </div>
  );
}

export default ArticleCardCmp;
