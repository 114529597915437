import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import ArticleCardCmp from '../components/ArticleCardCmp';

const ArticlesPageTemplate = ({
  pageContext,
  data: { allContentfulArticle },
}) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <Layout>
      <Seo title='Articles' />

      <div className=''>
        <div className='container py-6 pl-0 pr-0'>
          <section className='text-gray-700 body-font'>
            <div className='container px-5 mx-auto'>
              <div className='flex flex-col w-full h-full mb-12 text-center'>
                <div className='grid items-start grid-cols-1 gap-4 dark-text'>
                  {allContentfulArticle.edges.map(({ node }) => (
                    <div className='flex grow' key={node.id}>
                      <ArticleCardCmp
                        key={node.id}
                        title={node.title}
                        slug={node.slug}
                        description={node.description.description}
                        image={`/images/adam/langino-article.jpg`}
                        author='Adam J. Langino, Esq.'
                      />
                    </div>
                  ))}
                </div>
                <div className='mt-10'>
                  {!isFirst && (
                    <Link
                      to={
                        prevPage !== 1 ? `/articles/${prevPage}` : '/articles'
                      }
                      rel='prev'
                      className='px-4 py-2 ml-6 text-white rounded shadow-sm sm:relative sm:top-4 hover:no-underline bg-primary hover:bg-secondary'
                    >
                      ← Previous Page
                    </Link>
                  )}
                  {!isLast && (
                    <Link
                      to={`/articles/${nextPage}`}
                      rel='next'
                      className='px-4 py-2 ml-6 text-white rounded shadow-sm sm:relative sm:top-4 hover:no-underline bg-primary hover:bg-secondary'
                    >
                      Next Page →
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulArticle(skip: $skip, limit: $limit) {
      edges {
        node {
          title
          slug
          publishedDate
          node_locale
          id
          description {
            description
          }
        }
      }
    }
  }
`;

export default ArticlesPageTemplate;
